import React from "react";
import Translation from "../translation/translation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { changeLang } from "../../reducers/actions/index";
import { connect } from "react-redux";
import "./howPage.scss";
import { navigate } from "gatsby";
import Checkbox from "@material-ui/core/Checkbox";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';
class HowPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false      
    };
  }

  UNSAFE_componentWillMount() {
    
    
    if (this.props.langSaved) {
      this.props.changeLang(this.props.langSaved);
    }
    if (typeof window !== "undefined") {
      if (
        localStorage.getItem("qvax-howitworks-accepted") ||
        this.props.optinAccepted
      ) {
        this.setState({
          optin1Checked: true,
          // optin15yrs1Checked: true,
          // optin15yrs2Checked: true,
          optin2Checked: true,
          optin3Checked: true,
          optin4Checked: true,
          optin5Checked: true,
        });
      }
      if (localStorage.getItem("qvax-selected-region")) {
        const region = localStorage.getItem("qvax-selected-region");
        if (region === "Bruxelles") {
          this.setState({ showBrux: true });
        }
      }
    }
  }

  render() {
    return this.state.showBrux ? (
      <main className="how_page">
        <div className="titles">
          {!this.props.logged && (
            <span className="back" onClick={() => this.goBack()}>
              <ArrowBackIosIcon />
              <Translation trl="back_region" />
            </span>
          )}
          {!this.props.logged && (
            <span className="back_mobile" onClick={() => this.goBack()}>
              <ArrowBackIosIcon />
              <Translation trl="back" />
            </span>
          )}
          {this.props.logged && (
            <span
              className="back"
              onClick={() =>
                navigate("/", { state: { lang: this.props.lang } })
              }
            >
              <ArrowBackIosIcon />
              <Translation trl="back_list" />
            </span>
          )}
          {this.props.logged && (
            <span
              className="back_mobile"
              onClick={() =>
                navigate("/", { state: { lang: this.props.lang } })
              }
            >
              <ArrowBackIosIcon />
              <Translation trl="back" />
            </span>
          )}
          {/*  <h1><Translation trl="how_title"/></h1> */}
        </div>
        <div className="full_text" id="nl">
          <h1>NL</h1>
          <p>
            Het Brussels Hoofdstedelijk Gewest maakt geen gebruik van QVAX om de
            reservelijst voor de Covid-19-vaccinatie te beheren. Alle informatie
            over de vaccinatie in het Brusselse gewest vindt u op de website{" "}
            <a href="https://coronavirus.brussels" target="_blank">
              https://coronavirus.brussels
            </a>
          </p>
        </div>
        <div className="full_text" id="fr">
          <h1>FR</h1>
          <p>
            La Région de Bruxelles-Capitale n’utilise pas QVAX pour gérer la
            liste de réserve pour la vaccination Covid-19. Vous trouverez toutes
            les informations à propos de la vaccination en région bruxelloise
            via le site{" "}
            <a href="https://coronavirus.brussels" target="_blank">
              https://coronavirus.brussels
            </a>
          </p>
        </div>
        <div className="full_text" id="fr">
          <h1>DE</h1>
          <p>
            Die Region Brüssel-Hauptstadt verwendet QVAX nicht zur Verwaltung
            der Reserveliste für die Covid-19-Impfung. Alle Informationen über
            Impfungen in der Region Brüssel finden Sie auf der Website{" "}
            <a href="https://coronavirus.brussels" target="_blank">
              https://coronavirus.brussels
            </a>
          </p>
        </div>
        <div className="full_text" id="fr">
          <h1>EN</h1>
          <p>
            The Brussels-Capital Region does not use QVAX to manage the reserve
            list for the Covid-19 vaccination. You can find all the information
            about vaccination in the Brussels Region on the website{" "}
            <a href="https://coronavirus.brussels" target="_blank">
              https://coronavirus.brussels
            </a>
          </p>
        </div>
      </main>
    ) : (
      <main className="how_page">
        <div className="titles">
          {!this.props.logged && (
            <span className="back" onClick={() => this.goBack()}>
              <ArrowBackIosIcon />
              <Translation trl="back_region" />
            </span>
          )}
          {!this.props.logged && (
            <span className="back_mobile" onClick={() => this.goBack()}>
              <ArrowBackIosIcon />
              <Translation trl="back" />
            </span>
          )}
          {this.props.logged && (
            <span
              className="back"
              onClick={() =>
                navigate("/", { state: { lang: this.props.lang } })
              }
            >
              <ArrowBackIosIcon />
              <Translation trl="back_list" />
            </span>
          )}
          {this.props.logged && (
            <span
              className="back_mobile"
              onClick={() =>
                navigate("/", { state: { lang: this.props.lang } })
              }
            >
              <ArrowBackIosIcon />
              <Translation trl="back" />
            </span>
          )}
          <h1>
            <Translation trl="how_title" />
          </h1>
          <p>
            <Translation trl="how_general_sub_title" />
          </p>
          </div>
          <div className="actions_govapp">
          <button
              className="primary"
              onClick={() => window.open('https://apps.apple.com/us/app/govapp/id1620323239')}
            >
              <span>
                App Store
              </span>
            </button>
            <button
              className="primary"
              onClick={() => window.open('https://play.google.com/store/apps/details?id=be.fgov.govapp')}
            >
              <span>
                Google play
              </span>
            </button>
          </div>
        <div className="check_section">
          <p>
            <Translation trl="how_check_1" />
          </p>
          <div className="intro_checkbox">
            <Checkbox
              checked={this.state.optin1Checked}
              onChange={e => this.setState({ optin1Checked: e.target.checked })}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <label>
              <Translation trl="compris" />
            </label>
          </div>

          {/* <p>
            <Translation trl="how_check_15yrs1" />
          </p>
          <div className="intro_checkbox">
            <Checkbox
              checked={this.state.optin15yrs1Checked}
              onChange={e =>
                this.setState({ optin15yrs1Checked: e.target.checked })
              }
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <label>
              <Translation trl="compris" />
            </label>
          </div> */}
{/* 
          {(this.props.lang === "nl" ||
            this.props.lang === "en" ||
            this.props.lang === "fr") && (
            <div>
              <p>
                <Translation trl="how_check_15yrs2_part1" />
                <a
                  style={{ color: "#236ed3", textDecoration: "underline" }}
                  target="_blank"
                  href="https://www.laatjevaccineren.be/12-15-jarigen"
                >
                  <Translation trl="here_link" />
                </a>
                <Translation trl="how_check_15yrs2_part2" />
              </p>
              <div className="intro_checkbox">
                <Checkbox
                  checked={this.state.optin15yrs2Checked}
                  onChange={e =>
                    this.setState({ optin15yrs2Checked: e.target.checked })
                  }
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <label>
                  <Translation trl="compris" />
                </label>
              </div>
            </div>
          )} */}

          {/* {this.props.lang === "de" && (
            <div>
              <p>
                <Translation trl="how_check_15yrs2" />
              </p>
              <div className="intro_checkbox">
                <Checkbox
                  checked={this.state.optin15yrs2Checked}
                  onChange={e =>
                    this.setState({ optin15yrs2Checked: e.target.checked })
                  }
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <label>
                  <Translation trl="compris" />
                </label>
              </div>
              <p></p>
            </div>
          )} */}

          <p>
            <Translation trl="how_check_2" />
          </p>
          <div className="intro_checkbox">
            <Checkbox
              checked={this.state.optin2Checked}
              onChange={e => this.setState({ optin2Checked: e.target.checked })}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <label>
              <Translation trl="compris" />
            </label>
          </div>
          <p>
            <Translation trl="how_check_3" />
          </p>
          <div className="intro_checkbox">
            <Checkbox
              checked={this.state.optin3Checked}
              onChange={e => this.setState({ optin3Checked: e.target.checked })}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <label>
              <Translation trl="compris" />
            </label>
          </div>
          <p>
            <Translation trl="how_check_4" />
          </p>
          <div className="intro_checkbox">
            <Checkbox
              checked={this.state.optin4Checked}
              onChange={e => this.setState({ optin4Checked: e.target.checked })}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <label>
              <Translation trl="compris" />
            </label>
          </div>
          <p>
            <Translation trl="how_check_5" />
          </p>
          <div className="intro_checkbox">
            <Checkbox
              checked={this.state.optin5Checked}
              onChange={e => this.setState({ optin5Checked: e.target.checked })}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <label>
              <Translation trl="compris" />
            </label>
          </div>
        </div>
        <div className="steps_container">
          <div className="steps_section">
            <div className="titles">
              <h2>
                <Translation trl="how_subtitle1" />
              </h2>
            </div>
            <div className="steps">
              <div className="line" />
              <div className="step">
                <span>1</span>
                <div>
                  <h4>
                    <Translation trl="step1" />
                  </h4>
                  <p>
                    <Translation trl="how_text1a" /> (<a className="govapp_link" href="https://apps.apple.com/us/app/govapp/id1620323239" target="_blank">App Store</a> - <a className="govapp_link" href="https://play.google.com/store/apps/details?id=be.fgov.govapp" target="_blank">Google play</a>)
                    <Translation trl="how_text1b" /> <a className="govapp_link" href="https://www.govapp.be/" target="_blank">https://www.govapp.be/</a>
                  </p>
                </div>
              </div>
              <div className="step">
                <span>2</span>
                <div>
                  <h4>
                    <Translation trl="step2" />
                  </h4>
                  <p>
                    <Translation trl="how_text2" />
                  </p>
                </div>
              </div>
              <div className="step">
                <span>3</span>
                <div>
                  <h4>
                    <Translation trl="center_choice" />
                  </h4>
                  <p>
                    <Translation trl="how_text3" />
                  </p>
                </div>
              </div>
              <div className="step">
                <span>4</span>
                <div>
                  <h4>
                    <Translation trl="dispo_choice" />
                  </h4>
                  <p>
                    <Translation trl="how_text4" />
                  </p>
                </div>
              </div>
              <p className="last_step">
                <strong>
                  <Translation trl="bravo" />
                </strong>
                &nbsp;
                <Translation trl="how_bravo_text" />
              </p>
            </div>
          </div>
          <div className="steps_section">
            <div className="titles">
              <h2>
                <Translation trl="how_subtitle2" />
              </h2>
            </div>
            <div className="steps">
              <div className="line" />
              <div className="step">
                <span>1</span>
                <div>
                  <h4>
                    <Translation trl="notif_choice" />
                  </h4>
                  <p>
                    <Translation trl="how_text5" />
                  </p>
                </div>
              </div>
              <div className="step">
                <span>2</span>
                <div>
                  <h4>
                    <Translation trl="accept_invit" />
                  </h4>
                  <p>
                    <Translation trl="how_text6" />
                  </p>
                </div>
              </div>
              <div className="step">
                <span>3</span>
                <div>
                  <h4>
                    <Translation trl="rdv" />
                  </h4>
                  <p>
                    <Translation trl="how_text7" />
                  </p>
                </div>
              </div>
              <p className="last_step">
                <strong>
                  <Translation trl="bravo2" />
                </strong>
                &nbsp;
                <Translation trl="how_text8" />
              </p>
            </div>
          </div>
        </div>

        <div className="actions">
          {!this.isFormValid() && (
            <span className="explain">
              <Translation trl="check_all" />
            </span>
          )}
          {!this.props.logged && (
            <button
              className={this.isFormValid() ? "primary" : "primary disabled"}
              onClick={() => this.nextPage()}
            >
              <span>
                <Translation trl="next_step" />
              </span>
            </button>
          )}
        </div>
      </main>
    );
  }

  nextPage() {
    if (typeof window !== "undefined") {
      localStorage.setItem("qvax-howitworks-accepted", "true");
      switch (this.props.lang) {
        case "fr": {
          STRS_ENV === "development"
            ? navigate("/register", { state: { lang: this.props.lang } })
            : (window.location.href =
                "https://qvax.queue-it.net/?c=qvax&e=qvaxprod&t=http%3A%2F%2Fwww.qvax.be%2Fregister&cid=fr-fr");
          break;
        }
        case "nl": {
          STRS_ENV === "development"
            ? navigate("/register", { state: { lang: this.props.lang } })
            : (window.location.href =
                "https://qvax.queue-it.net/?c=qvax&e=qvaxprod&t=http%3A%2F%2Fwww.qvax.be%2Fregister&cid=nl-nl");
          break;
        }
        default: {
          STRS_ENV === "development"
            ? navigate("/register", { state: { lang: this.props.lang } })
            : (window.location.href =
                "https://qvax.queue-it.net/?c=qvax&e=qvaxprod&t=http%3A%2F%2Fwww.qvax.be%2Fregister");
        }
      }
    }
  }

  isFormValid() {
    if (this.props.lang === "nl" || this.props.lang === "fr") {
      return (
        this.state.optin1Checked &&
        // this.state.optin15yrs1Checked &&
        // this.state.optin15yrs2Checked &&
        this.state.optin2Checked &&
        this.state.optin3Checked &&
        this.state.optin4Checked &&
        this.state.optin5Checked
      );
    }

    return (
      this.state.optin1Checked &&
      this.state.optin2Checked &&
      this.state.optin3Checked &&
      this.state.optin4Checked &&
      this.state.optin5Checked
    );
  }

  goBack() {
    if (this.isClientSide()) {
      localStorage.removeItem("qvax-selected-region");
      navigate("/region", { state: { lang: this.props.lang } });
    }
  }

  isSessionValid(session) {
    const expireDate = new Date(session.expiresOn);
    const dateNow = new Date();
    return expireDate > dateNow;
  }

  isClientSide() {
    return typeof window !== "undefined";
  }
}

const mapStateToProps = state => ({
  lang: state.lang,
});

const mapDispatchToProps = () => {
  return {
    changeLang,
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(HowPage);
